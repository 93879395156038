@import "./components/steps/steps";

.custom-pdp {
  display: inline-block;
  height: 100%;
  width: 100%;
  .breadcrumbs {
    @media screen and (min-width: 991px) {
      padding-left: 15px;
    }
  }
  #canvas {
    @media screen and (max-width: 991px) {
      .col.cursor-pointer {
        text-align: center;
      }
    }
  }
  #frame-options {
    margin-bottom: 2rem;
  }
  .gallery-container {
    z-index: 10;
    position: absolute;
    top: 5.5rem;
    left: 51%;
    .view-image-details-container {
      bottom: 2rem;
    }
    @media screen and (max-width: 991px) {
      left: 0;
      top: -0.5rem;
      position: relative;
      margin-bottom: 0;
      max-height: initial;
      padding-top: 0.5rem;
      .view-image-details-container {
        position: relative !important;
        bottom: 0;
        top: 1rem;
        margin-bottom: 0.5rem;
      }
      .pdp-slider-modal .vertical-slider-wrapper .images-wrapper {
        position: relative;
        justify-content: center;
        @media screen and (max-width: 991px) {
          justify-content: flex-start;
        }
      }
    }
  }
  .modal-open {
    .gallery-container {
      left: 45%;
      @media screen and (max-width: 991px) {
        left: 108%;
      }
      bottom: -5.1rem;
    }
  }
  .toolbar-wrapper {
    bottom: 0;
    @media screen and (max-width: 991px) {
      z-index: 10;
    }
  }

  .custom-left {
    min-width: 100%;
    max-width: 100%;
    width: 100%;
    min-height: 33.5rem;
    max-height: 33.5rem;
    height: 33.5rem;
    @media screen and (min-width: 992px) {
      top: 0;
      z-index: 2;
      position: sticky;
    }
    &.modal-open {
      ~ .toolbar-wrapper {
        z-index: 9;
      }
      position: initial;
      .view-image-details-container {
        bottom: 15.8rem;
        left: -440px;
      }
    }
    canvas {
      width: 100% !important;
      height: 100% !important;
    }
  }

  .custom-right {
    width: 100%;
    padding: 0 15px;
    .select-frame {
      &.slide-in {
        animation: slideIn 0.5s ease-in-out;
      }
      &.slide-out {
        animation: slideOut 0.5s ease-out;
      }
    }
    .view-more-title {
      background: #f7f7f7;
      cursor: pointer;
      padding: 10px 10px 10px 25px;
      border-radius: 2px;
      width: calc(100% - 15px);
      // width: 100%;
      margin-right: 15px;
      margin-left: 15px;
      display: flex;
      justify-content: space-between;
      h1 {
        color: $default;
        font-weight: 300;
      }
      .minus {
        margin-top: 0px;
      }
    }
    .custom-name {
      // font-weight: 500;
      // font-size: 2.4rem;
      color: $gray-darken-3;
      margin: 10px 0;
    }

    .custom-sku {
      width: 100%;
      display: inline-block;
      margin: 10px 0 0;

      p {
        float: left;
        font-weight: 100;
        font-size: 2.4rem;
        color: $default;
      }

      h1 {
        float: right;
        color: $default;
        font-size: 2.4rem;
        font-weight: 400;
      }
    }
  }
}

@media screen and (min-width: 992px) {
  .custom-pdp {
    height: calc(100% - 230px);
    .custom-left {
      max-width: 66.6%;
      min-width: 66.6%;
      position: sticky;
      width: 66.6%;
      float: left;
      padding: 0 30px;
      top: 4rem;
      min-height: calc(100vh - 230px - 4rem);
      max-height: calc(100vh - 230px - 4rem);
      height: calc(100vh - 230px - 4rem);
    }

    .custom-right {
      width: 33.3%;
      float: right;
      padding: 0 15px;
    }
  }
}

@media screen and (max-width: 991px) {
  .custom-pdp {
    .custom-right {
      .view-more-title {
        margin-left: 0px;
        margin-right: 15px;
        width: 100%;
      }
      .view-more-options-container {
        margin-left: 0px;
        margin-right: 0px;
      }
      .selector-container {
        padding-left: 0px;
      }
      .gallery-container {
        max-width: 60%;
        margin: 0 auto;
        .media-gallery {
          margin-bottom: 4rem;
          top: 1rem;
        }
      }
    }
  }
}
