@import "./components/itemContainer/itemContainer";

.sidebar-container {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  background: #fff;
  z-index: 100;
  box-shadow: -3px 0 6px rgba(0, 0, 0, 0.38);
  cursor: initial;
  .filter-by {
    .modal-header {
      padding: 0;
      .modal-title {
        width: 100%;
        .row > .col > div {
          padding: 2rem 0 !important;
        }
      }
    }
    .items {
      height: 80vh;
    }
    .page-footer {
      padding: 2rem;
      background-color: $grey-lighten-4;
      margin: 0 -15px;
    }
  }
  .filters-sidebar {
    padding: 1.5rem 2.3rem;
    background: $gray-lighten-4;
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
    p {
      font-size: 1.4rem;
      font-weight: bold;
      margin-left: 2rem;
      margin-bottom: 0;
    }
  }
  .selected-option {
    .row {
      > div {
        &:last-of-type {
          padding-top: 2.5rem;
        }
      }
      img {
        max-width: 100%;
      }
    }
    .d-grid {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      &.stock {
        color: $default;
        p {
          &:last-of-type {
            font-weight: 400;
          }
        }
      }
      a {
        font-size: 1.4rem;
        text-decoration: underline !important;
      }
      p {
        font-size: 1.4rem;
        &:first-of-type {
          font-weight: 400;
        }
        &:last-of-type {
          font-weight: 300;
        }
      }
    }
  }
  .sidebar-title-section {
    position: relative;
    z-index: 10;
    background: $default;
    width: 100%;

    img {
      position: absolute;
      left: 5%;
      top: 1.8rem;
      cursor: pointer;
    }

    h1 {
      text-align: center;
      color: #fff;
      font-size: 40px;
      font-weight: 300;
      padding: 25px 0;
      max-width: 70%;
      margin: 0 auto;
      line-height: 3rem;
    }
  }

  .available-categories {
    display: flex;
    cursor: pointer;
    padding: 0 10px;
    flex-wrap: wrap;
    .select2 {
      // margin-right: 2rem;
      width: 100%;
    }
    p {
      text-align: center;
      font-size: 16px;
      color: $gray-darken-3;
      font-weight: 300;
      padding-bottom: 10px;
      transition: 0.2s border ease-in-out;
      border-bottom: none;

      &.selected {
        border-bottom: 0.5rem $default solid;
        font-weight: 600;
      }
    }
  }

  .scrollable-content {
    height: 100%;
    @media screen and (min-width: 992px) {
      overflow: scroll;
      &.finish-toggled {
        overflow: initial;
      }
    }
    overflow: auto;
    scrollbar-width: none;
    max-height: calc(100% - 200px);

    &::-webkit-scrollbar {
      width: 0;
    }
    .infinite-scroll-component {
      display: grid;
      grid-template-columns: repeat(7, 1fr);
      place-items: center;
      padding: 0 5px;
      @media screen and (max-width: 600px) {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
      }
    }

    .md-form {
      width: 100%;
      margin: 0 auto 20px;

      input {
        padding-left: 50px;
      }

      img {
        position: absolute;
        top: 10px;
        left: 10px;
      }
    }

    .filter-container {
      margin-top: 15px;
      box-shadow: rgba(0, 0, 0, 0.5) 0 10px 10px -10px;
      min-height: 53px;

      .filter-selector {
        width: 100%;
        height: 103px;

        .filter-type {
          float: left;
          width: calc(50% - 20px);
          height: 33px;
          line-height: 33px;
          margin: 5px 10px;
          border: 1px $gray solid;
          color: $gray-darken-3;
          text-align: center;
          border-radius: 5px;
          font-size: 14px;
          font-weight: 300;
          position: relative;

          &.selected {
            background: $default;
            color: $gray;
            border: none;
          }

          .toggle-arrow {
            position: absolute;
            right: 1rem;
            bottom: calc((33px - 7px) / 2);
            width: 0;
            height: 0;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-top: 5px solid $default;
            transition: all 0.25s ease-in-out;

            &.selected {
              transform: rotate(180deg);
              border-top: 5px solid $gray;
            }
          }
        }
      }

      .available-filters {
        padding: 20px 40px 50px;
        background: $gray-lighten-4;
        box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.16);

        &.hidden {
          padding: 0;
          box-shadow: none;
        }

        .slick-arrow {
          width: 0;
          height: 0;
          border-top: 7px solid transparent;
          border-bottom: 7px solid transparent;
          transition: all 0.25s ease-in-out;
          overflow: hidden;

          &.slick-disabled {
            opacity: 0;
          }

          &.slick-prev {
            border-right: 7px solid $default;
          }

          &.slick-next {
            border-left: 7px solid $default;
          }
        }

        .filter-option {
          float: left;
          margin-right: 10px;
          margin-bottom: 10px;
          max-width: 29%;
          max-height: 30px;
          cursor: pointer;

          > * {
            float: left;
          }

          .checkbox {
            width: 18px;
            height: 18px;
            border: 1px solid $gray;
            border-radius: 0.5rem;
            margin-right: 10px;
            background: #fff;
            margin-top: 6px;

            img {
              width: 18px;
              height: 18px;
            }
          }

          p {
            font-weight: 400;
            font-size: 1.1rem;
            max-width: calc(100% - 30px);
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: row;
            height: 30px;
          }
        }
      }
    }

    .infinite-scroll-component {
      // @media screen and (min-width: 992px) {
      //   max-height: 30rem;
      //   overflow: auto !important;
      // }
      // @media screen and (max-width: 991px) {
      //   overflow: visible !important;
      // }
      overflow: visible !important;
      padding-top: 0.5rem;
    }
  }

  .sidebar-footer {
    height: 120px;
    width: 100%;
    z-index: 10;
    position: absolute;
    bottom: 0;
    background: $gray-lighten-4;
    padding: 15px;

    .selected-option {
      margin-bottom: 5px;
      display: inline-block;

      > * {
        float: left;
      }

      p {
        font-size: 1.6rem;
        margin-right: 30px;
        color: $default;
      }

      h4 {
        font-size: 1.6rem;
        color: $gray-darken-3;
        line-height: 1.5;
      }
    }

    .apply-button,
    .reset-btn {
      width: 212px;
      height: 40px;
      padding: 8px 21px;
      @media screen and(max-width: 600px) {
        width: 150px;
        margin: 0;
      }
      // margin: 0 auto;
      border-radius: 5px;
      transition: 0.1s all linear;
      cursor: pointer;

      &:hover {
        box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18),
          0 4px 15px 0 rgba(0, 0, 0, 0.15);
      }

      p {
        font-weight: 500;
        font-size: 1.4rem;
        text-align: center;
        line-height: 24px;
      }
    }
    .apply-button {
      background: $default;
      color: #fff;
    }
    .reset-btn {
      background-color: white;
      color: $default;
      border: 1px solid $default;
    }
  }
}

@media screen and (min-width: 768px) {
  .sidebar-container {
    width: 56rem;

    .available-categories {
      padding: 0 15px;
    }

    .scrollable-content {
      .filter-container {
        .filter-selector {
          width: 100%;
          height: 33px;
          margin-bottom: 20px;

          .filter-type {
            width: 113px;
            margin: 0 10px;
          }
        }
      }

      .available-filters {
        padding: 20px 40px 50px;
        background: $gray-lighten-4;
        box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.16);

        &.hidden {
          padding: 0;
          box-shadow: none;
        }

        .slick-arrow {
          width: 0;
          height: 0;
          border-top: 7px solid transparent;
          border-bottom: 7px solid transparent;
          transition: all 0.25s ease-in-out;
          overflow: hidden;

          &.slick-disabled {
            opacity: 0;
          }

          &.slick-prev {
            border-right: 7px solid $default;
          }

          &.slick-next {
            border-left: 7px solid $default;
          }
        }

        .filter-option {
          p {
            font-size: 1.3rem !important;
          }
        }
      }
    }
  }
}
