.sidebar-item-container {
  width: 76px;
  height: 76px;
  // float: left;
  cursor: pointer;
  display: flex;
  justify-content: center;
  position: relative;
  @media screen and(max-width: 600px) {
    float: none;
  }

  .image-container {
    // padding: 10px;
    position: relative;

    img {
      width: 56px;
      height: 56px;
      border-radius: 5px;

      &.selected-image {
        border: 1px $default solid;
      }
    }

    &.player-display-badge {
      &:after {
        content: "";
        display: block;
        width: calc(100% - 40px);
        height: calc(100% - 40px);
        background: url("../../../../../../../../../../assets/icon/badge.svg")
          no-repeat;
        background-size: contain;
        z-index: 2;
        position: absolute;
        bottom: 10px;
        left: 30px;
        opacity: 0.7;
      }
    }

    .selected {
      position: absolute;
      width: 15px;
      height: 15px;
      bottom: 20px;
      right: 0;
    }
  }

  // .arrow-border {
  //   display: none !important;
  // }

  .toolbar-container {
    position: absolute;
    display: none !important;
    width: 260px;
    height: 360px;
    background: #fff;
    top: -380px;
    z-index: 10;
    border: 1px #000 solid;
    box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.3);
    padding: 40px;

    &.fabric-toolbar {
      height: 280px;
      top: -300px;
    }

    img {
      width: 180px;
      height: 180px;
    }

    p {
      margin: 5px 0;
      font-size: 1.4rem;
      font-weight: 300;
      text-align: center;
    }
  }

  .arrow {
    position: absolute;
    width: 0;
    height: 0;
    border-top: 15px #fff solid;
    border-right: 10px transparent solid;
    border-left: 10px transparent solid;
    top: -21px;
    left: 27px;
    z-index: 12;
  }

  .arrow-border {
    position: absolute;
    width: 0;
    height: 0;
    border-top: 16px #000 solid;
    border-right: 11px transparent solid;
    border-left: 11px transparent solid;
    top: -21px;
    left: 26px;
    z-index: 11;
  }
}

@media screen and (min-width: 768px) {
  .sidebar-item-container {
    .image-container {
      .selected {
        bottom: 20px;
        right: 0px;
      }

      &.player-display-badge {
        &:after {
          bottom: 20px;
          left: 20px;
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .sidebar-item-container {
    &:nth-of-type(4n + 3) {
      .toolbar-container {
        left: -128px;
      }
    }
    &:nth-of-type(4n + 4) {
      .toolbar-container {
        left: -198px;
      }
    }
  }
}
