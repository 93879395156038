@import "./components/relatedProducts/relatedProducts";
@import "./RoomScenes/roomScenes";

.generic-pdp {
  width: 100%;
  .breadcrumbs {
    @media screen and (min-width: 991px) {
      padding-left: 15px;
    }
  }
  .col-lg-8 {
    @media screen and (min-width: 1440px) {
      position: sticky;
      top: 0;
      min-height: calc(100vh - 230px - 4rem);
      max-height: calc(100vh - 230px - 4rem);
      height: calc(100vh - 230px - 4rem);
    }
    @media screen and (min-width: 992px) {
      position: sticky;
      top: 0;
      min-height: calc(100vh - 106px - 4rem);
      max-height: calc(100vh - 106px - 4rem);
      height: calc(100vh - 106px - 4rem);
    }
    .images-wrapper {
      width: 100%;
      height: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      bottom: -4rem;
      position: absolute;
      padding: 1rem 0;
      @media screen and (max-width: 991px) {
        bottom: -4rem;
      }
    }
    // .view-image-details-container {
    //   bottom: 4rem;
    //   position: absolute;
    //   @media screen and (max-width: 991px) {
    //     bottom: -4rem;
    //   }
    // }
  }
  .toolbar-wrapper {
    bottom: 0;
    @media screen and (max-width: 991px) {
      z-index: 10;
    }
  }

  .generic-options-container {
    display: flex;
    // padding: 30px;
    margin: 0 auto;
    flex-direction: column;
    @media screen and (max-width: 991px) {
      padding: 0;
    }
    .generic-left {
      // padding: 30px 0;
      width: 100%;
    }

    .generic-right {
      // padding: 30px 0;
      width: 100%;
    }
  }
}

// @media screen and (min-width: 576px) {
//   .generic-options-container {
//     width: 540px;
//   }
// }

// @media screen and (min-width: 768px) {
//   .generic-options-container {
//     width: 720px;
//   }
// }

// @media screen and (min-width: 992px) {
//   .generic-options-container {
//     width: 960px;
//   }

//   .generic-left {
//     padding: 15px !important;
//     width: 50% !important;
//     float: left;
//     border-right: 1px $gray solid;

//     &.fullscreen {
//       width: 100% !important;
//     }
//   }

//   .generic-right {
//     padding: 15px !important;
//     float: right;
//     width: 50% !important;
//   }
// }

// @media screen and (min-width: 1200px) {
//   .generic-options-container {
//     width: 1140px;
//   }

//   .generic-left {
//     padding-right: 100px !important;
//     &.fullscreen {
//       padding-right: 0 !important;
//     }
//   }

//   .generic-right {
//     padding-left: 100px !important;
//   }
// }
.browse-residential-commercial-wrap {
  padding: 0 9rem;
  width: 100%;
}
.browse-residential-commercial {
  background-color: #f6f7f8;
  max-height: 100%;
  button,
  a.btn.btn-default {
    color: #fff;
    font-family: $secondaryFontGibson;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 100% */
  }
  .browse-residential-commercial-left {
    padding-left: 9.8rem;
  }
  .browse-residential-commercial-subtitle {
    color: #404040;
    font-family: $secondaryFontGibson;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px; /* 100% */
    letter-spacing: 2px;
    text-transform: uppercase;
    margin-bottom: 2rem;
  }
  .browse-residential-commercial-title {
    color: #404040;
    font-family: $secondaryFontGibson;
    font-size: 72px;
    font-style: normal;
    font-weight: 100;
    line-height: 74px; /* 102.778% */
    margin-bottom: 6rem;
    max-width: 658px;
    @media screen and (max-width: 1200px) {
      font-size: 32px;
    }
  }
  .browse-residential-commercial-images {
    padding-right: 0;
    img {
      height: 100%;
    }
    div:first-of-type {
      margin-right: -200px;
    }
  }
}
.inspirations-lookbook-wrapper {
  padding: 0 9rem;
  position: relative;
  width: 100%;
  overflow: hidden;
  .inspirations-lookbook-content {
    position: absolute;
    top: 20%;
    width: 40%;
    .inspirations-lookbook-title {
      color: #fff;
      text-align: center;
      font-size: 10rem;
      font-family: $fontUtopia;
    }
    .inspirations-lookbook-text {
      font-family: $secondaryFontGibson !important;
      padding: 2rem 5rem;
      font-size: 2.8rem;
      font-weight: 400;
      color: #fff;
      text-align: center;
    }

    .btn-wrapper {
      display: flex;
      justify-content: center;
      a {
        font-size: 1.6rem;
        font-weight: 400;
      }
    }
  }
}

@media screen and (max-width: 1440px) {
  .inspirations-lookbook-wrapper {
    .inspirations-lookbook-content {
      .inspirations-lookbook-title {
        font-size: 6rem;
      }
      .inspirations-lookbook-text {
        font-size: 2rem;
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .inspirations-lookbook-wrapper {
    padding: 0 5rem;
    .inspirations-lookbook-content {
      width: 35%;
      top: 10%;
      .inspirations-lookbook-title {
        font-size: 3rem;
      }
      .inspirations-lookbook-text {
        font-size: 1.4rem;
        padding: 1rem 3rem;
      }
      .btn-wrapper {
        a {
          height: 3.2rem;
        }
      }
    }
  }
}
@media screen and (max-width: 750px) {
  .inspirations-lookbook-wrapper {
    padding: 0 2rem;
    .inspirations-lookbook-content {
      left: 0;
      top: 5%;
      width: 100%;
      .inspirations-lookbook-text {
        padding: 2rem 5rem;
      }
      .btn-wrapper {
        a {
          font-size: 1.4rem;
          width: 13rem;
          font-weight: 400;
        }
      }
    }
  }
}
