.summary-modal {
  &.modal-dialog {
    max-width: 67.6rem;
  }
  .summary-header {
    background: #6c808b;
    padding: 1.9rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 7rem;
    display: flex;
    top: 0;
    z-index: 1001;
    .close-icon {
      cursor: pointer;
      position: absolute;
      top: 1.4rem;
      left: 1.5rem;
    }
    h1 {
      color: white;
    }
  }
  .summary-wrapper {
    z-index: 1000;
    padding: 3.8rem 10rem;
    background-color: rgba(255, 255, 255, 0.95);
    flex-direction: column;
    .close-icon {
      position: absolute;
      top: 1.5rem;
      right: 1.5rem;
      cursor: pointer;
    }
    .options {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      grid-row: 2rem;
      grid-gap: 3rem;
      max-height: 68vh;
      overflow-y: auto;
      .contrastDrawers + .contrastDrawers {
        display: none !important;
      }
      > div {
        > div {
          > p {
            &:first-of-type {
              width: 30%;
            }
          }
          > div {
            &:first-of-type {
              width: 60%;
              margin-left: 2rem;
            }
          }
        }
      }
      img {
        width: 2.5rem;
        height: 2.5rem;
      }
      p {
        font-size: 1.6rem;
        font-weight: 400;
        &.value {
          color: #888888;
          margin-left: 0.8rem;
        }
      }
      h2 {
        text-transform: capitalize;
        color: $default;
        font-size: 2.4rem;
      }
    }
    h1 {
      font-size: 4rem;
      color: $default;
      margin-bottom: 1rem;
    }

    @media screen and (max-width: 991px) {
      padding: 3.8rem 1.5rem;
      .options {
        grid-template-columns: repeat(1, 1fr);
      }
    }
  }
}
